<template>
  <div class="w-14 h-full bg-white text-center sticky top-38" style="min-width: 54px; max-width: 54px; overflow: hidden;">
    <div class="pt-0">
      <div v-for="st in states[lead().state]" v-bind:key="st" @click="select(st)" :class="[st == currentMenu() ? 'flex flex-col items-center justify-center h-9 text-white text-xl rounded border-1 border-blue-700 bg-blue-700 cursor-pointer m-2 mt-0' : 'flex flex-col items-center justify-center h-9 text-blue-800 text-xl rounded border-2 border-white hover:border-blue-600 cursor-pointer m-2 mt-0']">
        <i :class="[menus[st]]"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Side",
  data() {
    return {
      menus: {
        "customer": "fal fa-user",
        "vehicle": "fal fa-car",
        "features": "fal fa-stars",
        "checks": "fal fa-check-double",
        "pictures": "fal fa-camera",
        "damages": "fal fa-car-crash",
        "summary": "fal fa-clipboard-list",
      },
      states: {
        "INITIAL": ["customer", "vehicle", "checks", "features", "pictures", "damages", "summary"]
      }
    }
  },
  methods: {
    lead() {
      return this.$store.getters['seller/lead'];
    },
    currentMenu() {
      return this.$store.getters['tradein/menu'];
    },
    select(menu) {
      return this.$store.commit('tradein/setMenu', menu);
    }
  }
}
</script>

<style scoped>

</style>